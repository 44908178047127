import React, {Component} from "react";
import { Link } from "react-router-dom";
import {team} from "../assets/data/data";
import Lottie from './animations/CompanyAnimation'
import MetaTags from 'react-meta-tags';
import ScrollContainer from 'react-indiana-drag-scroll'
import ReactGA from 'react-ga';


class About extends Component {

    componentDidMount() {
        document.body.classList.add('page-company');
        ReactGA.pageview(window.location.pathname + window.location.search);
    }

    componentWillUnmount() {
        document.body.classList.remove('page-company');
    }

    render() {
        return (
            <div className={"content"}>
                <MetaTags>
                    <title>Eternic – International Payments Company</title>
                    <meta id="meta-description" name="description" content="Eternic is a FinTech specializing in B2B international payments. Our global payments and settlement platform provides faster, cheaper and safer transfers." />
                    <meta id="og-title" property="og:title" content="Eternic" />
                    <meta id="og-image" property="og:image" content="https://www.eternic.io/assets/hyperlink-photo.jpg" />
                </MetaTags>

                <section id="intro">
                    <div className="container">
                        <div className="row">
                            <div className="col-12 text-left text-md-center">
                                <h1>Our Company</h1>
                                <div className="text-wrapper">
                                <p>Improving international payments and settlement</p>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>

                <section id="the-team">
                    <div className="container-fluid">
                        <ScrollContainer nativeMobileScroll className="scroll-container row">
                            <div className="team-wrapper">
                                {team
                                    .map((item, index) => (
                                        <div key={index} className="team-members">
                                            <a rel="noopener noreferrer" href={item.link} target="_blank">
                                                <img draggable={false} className="logo" width="100%"
                                                     src={require(`../assets/images/team/${index+1}.jpg`)}
                                                     alt={item.name}/>
                                                <div className="description">
                                                    <p>{item.name}</p>
                                                    <span>{item.role}</span>
                                                </div>
                                            </a>
                                        </div>
                                    ))
                                }
                            </div>
                        </ScrollContainer>
                    </div>
                    <div className="container">
                        <div className="row">
                            <div className="col-md-6 offset-md-3 text-md-center">
                                <h2>The Team</h2>
                                <p>Eternic's team is comprised of industry experts with years of experience in banking systems, payment technologies, credit card solutions, IT security, financial technology and software development, and building startups. Since 2010, our team members have been involved in delivering successful payment solutions for companies such as Klarna Bank, Collector Bank, Tandem Bank, and Neonomics.</p>
                            </div>
                        </div>
                    </div>
                </section>

                <section>
                    <div className="container">
                        <div className="row">
                            <div className="col-md-6 align-self-center" id="lottie-col">
                                <Lottie />
                            </div>
                            <div className="col-md-5 align-self-center">
                            <div>
                                    <h2>Our Mission</h2>
                                    <p>Our mission is to fundamentally improve banking settlement and foreign exchange transactions by enabling real-time bank-to-bank transactions and oversight, eliminating the need for correspondent banks in between to mitigate and manage risk. Working in partnership with central banks and leading financial institutions, our goal is to develop a global platform for faster, more secure, and less expensive international financial transactions.</p>
                                </div>
                                <div>
                                    <h2>Our Founding</h2>
                                    <p>Eternic was founded in 2018 by payments specialist Christoffer Andvig following years of research and development focused on improving international payments and settlement. Through his work creating Neonomics, a leading open banking platform, Chris recognized the significant pressure global commerce and open banking would put on international banking systems, as well as the promise emerging technologies such as distributed ledger and tokenization held to meet those demands.</p>
                                    <p>Following several successful POC trials using blockchain technology, Christoffer teamed up with Constanti AG in order to build a DLT-based platform that would revolutionize the current cross-border payments and settlement systems. That platform is Eternic.</p>
                                </div>
                                <div>
                                    <h2>Where We Are Now</h2>
                                    <p>The Eternic platform is currently in development through Fintech Innovation in Oslo, Norway. Through the Fintech Innovation hub, we can access and collaborate with top advisors and developers in the payments space. The Eternic solution is schedule to launch in Q1 of 2021. Our POC phase is complete and we are currently building a network of testing partners though our <Link className='link' to={'/partnership'}>VIA Partner Program.</Link></p>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>

            </div>
        )
    }
}

export default About;
import React, { Component } from "react";
import { Link } from "react-router-dom";
import MetaTags from "react-meta-tags";
import Lottie from "./animations/PartnerProgramAnimation";
import ReactGA from "react-ga";

class PartnerProgram extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isTop: true
    };
  }

  componentDidMount() {
    document.body.classList.add("page-partner");
    ReactGA.pageview(window.location.pathname + window.location.search);

    document.addEventListener("scroll", () => {
      if (window.location.pathname === "/partnership") {
        const isTop =
          window.innerHeight + window.scrollY <=
          document.body.offsetHeight - 240;
        if (isTop !== this.state.isTop) {
          this.setState({ isTop });
        }
      } else {
        const viaLinkTrigger =
          window.innerHeight + window.scrollY <=
          document.body.offsetHeight - 550;
        if (viaLinkTrigger !== this.state.viaLinkTrigger) {
          this.setState({ viaLinkTrigger });
        }
      }
    });
  }

  componentWillUnmount() {
    document.body.classList.remove("page-partner");
  }

  render() {
    return (
      <div className="container">
        <div className={"content"}>
          <MetaTags>
            <title>Eternic – Partner with us</title>
            <meta
              id="meta-description"
              name="description"
              content="Eternic is a FinTech specializing in low-cost B2B international payments. Learn more about our global payments and settlement platform and partner program."
            />
            <meta id="og-title" property="og:title" content="Eternic" />
            <meta
              id="og-image"
              property="og:image"
              content="https://www.eternic.io/assets/hyperlink-photo.jpg"
            />
          </MetaTags>

          <section id="partner-sticky-image">
            <div className="image-wrapper">
              <img
                className={this.state.isTop ? "img-top" : "img-bottom"}
                src={require("../assets/images/globe.gif")}
                alt=""
              />
            </div>
          </section>

          <section id={"intro"}>
            <div className="background-wrapper">
              <div className="background-element"></div>
            </div>
            <div className="row">
              <div className="col-md-12 col-xl-5">
                <h1>Partner with Eternic</h1>
                <div className="text-wrapper">
                  <p>
                    Are you a financial institution working with international
                    money transfers? We can help. <br />
                    <br />
                    Our global payment and settlement platform can help you
                    modernize your international payments system quickly and
                    easily to meet higher demand while lowering your costs. Join
                    our partner program and start saving on every transaction.
                  </p>
                  <div className="punch-line d-none d-md-block">
                    Low cost<span className="dot">•</span>
                    System to system integration
                    <span className="dot">•</span>Near real-time settlement
                  </div>
                  <div className="punch-line d-block d-md-none">
                    <div>Low cost<span className="dot">•</span>System to system integration<span className="dot">•</span></div>
                    <div>Near real-time settlement</div>
                  </div>
                  <div className="text-center text-md-left">
                    <Link className="btn" to="/contact">
                      Tell me more
                    </Link>
                  </div>
                </div>
              </div>
            </div>
          </section>
          <section id={"platform"}>
            <div className="row">
              <div className="col-md-12 col-xl-5">
                <h2>International Payments Platform</h2>
                <p>
                  Eternic is a B2B international payments platform that is
                  perfect for banks and financial service companies that are
                  making regular, high-volume international transfers.
                  <br />
                  <br />
                  Our DLT-based transfer mechanism works as a “super
                  correspondent” to provide you with a faster, cheaper and safer
                  international payments system that surpasses the traditional
                  correspondent banking methods.
                </p>
              </div>
            </div>
          </section>
          <section className="d-block d-md-none">
            <div className="row">
              <div className="col-12">
                <Lottie />
              </div>
            </div>
          </section>

          <section id={"benefits"}>
            <div className="row">
              <div className="col-md-12 col-xl-5">
                <h2>Eternic Platform Benefits</h2>
                <div>
                  <h3>Cost Effective</h3>
                  <p>
                    Reduce fees to cents per transaction regardless of
                    transaction amount. The total fee consists of use of the
                    platform (cents) and transfer on national payment rails
                    (usually under 1 USD).
                  </p>
                </div>
                <div>
                  <h3>Minimize Liquidity Exposure</h3>
                  <p>
                    Define your settlement strategy to minimize your liquidity
                    risk exposure. If time difference does not allow for
                    immediate settlement, choose your settlement preference;
                    either optimizing time-to-recipient or liquidity costs.
                  </p>
                </div>
                <div>
                  <h3>Streamlined</h3>
                  <p>
                    Automatically identify STP transactions and eliminate manual
                    intervention. Apply specific governance and local
                    regulations to every transaction using smart contracts.
                  </p>
                </div>
                <div>
                  <h3>Secure</h3>
                  <p>
                    Operate with confidence using permission-based ledger
                    technology. Enable participants to see their transaction
                    data for status, oversight and reconciliation.
                  </p>
                </div>
                <div>
                  <h3>Compatible</h3>
                  <p>
                    Connect through well-defined APIs or existing messaging
                    systems (Swift or ISO 20022). Straightforward deployment
                    methods make it easy for you to join and stay connected.
                  </p>
                </div>
              </div>
            </div>
          </section>

          <section id={"read-more-about"}>
            <div className="row">
              <div className="col-12">
                <img
                  className="img-fluid mx-auto d-block d-xl-none"
                  src={require("../assets/images/globe.gif")}
                  alt="Globe Animated"
                />
              </div>
              <div className="col-12 col-md-12 col-xl-6 title-wrapper">
                <h2>Read more about us</h2>
              </div>
              <a
                href={"/files/distributed-ledger-technologies_analysis.pdf"}
                rel="noopener noreferrer"
                target="_blank"
                className="col-12 col-md-12 col-xl-6 col-rma-wrapper"
              >
                <div className="rma-inner">
                  Our solution uses distributed ledger technology (DLT). See our
                  white paper on how we selected the right one.
                  <svg
                    width="13"
                    height="16"
                    viewBox="0 0 13 16"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                    className="arrow"
                  >
                    <path
                      d="M0.113371 8.875L0.113372 7.125L9.90116 7.125L5.40407 2.125L6.53779 0.875L13 8L6.53779 15.125L5.40407 13.875L9.90116 8.875L0.113371 8.875Z"
                      fill="white"
                    />
                  </svg>
                </div>
              </a>
              <a
                href="https://medium.com/eternic/https-medium-com-eternic-part-1-cryptocurrency-bis-looks-beyond-the-hype-8b39c21bfdd7?source=friends_link&sk=ba206186ed40e218e5496b3eb11b6b71"
                rel="noopener noreferrer"
                target="_blank"
                className="col-12 col-md-12 col-xl-6 col-rma-wrapper"
              >
                <div className="rma-inner">
                  We share the Bank of International Settlements (BIS) view on
                  DLT application. See our series examining BIS positions on
                  emerging cross-border payment technologies.
                  <svg
                    width="13"
                    height="16"
                    viewBox="0 0 13 16"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                    className="arrow"
                  >
                    <path
                      d="M0.113371 8.875L0.113372 7.125L9.90116 7.125L5.40407 2.125L6.53779 0.875L13 8L6.53779 15.125L5.40407 13.875L9.90116 8.875L0.113371 8.875Z"
                      fill="white"
                    />
                  </svg>
                </div>
              </a>
              <a
                href={"/files/info-sheet_learn-more-about-eternic.pdf"}
                rel="noopener noreferrer"
                target="_blank"
                className="col-12 col-md-12 col-xl-6 col-rma-wrapper"
              >
                <div className="rma-inner">
                  Click here to read our Infosheet
                  <svg
                    width="13"
                    height="16"
                    viewBox="0 0 13 16"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                    className="arrow"
                  >
                    <path
                      d="M0.113371 8.875L0.113372 7.125L9.90116 7.125L5.40407 2.125L6.53779 0.875L13 8L6.53779 15.125L5.40407 13.875L9.90116 8.875L0.113371 8.875Z"
                      fill="white"
                    />
                  </svg>
                </div>
              </a>
            </div>
          </section>
        </div>
      </div>
    );
  }
}

export default PartnerProgram;

import React, { Component } from "react";
import axios from "axios";
import MetaTags from "react-meta-tags";
import ReactGA from 'react-ga';

const API_PATH = "https://www.eternic.io/api/contact.php";

class Contact extends Component {
  constructor(props) {
    super(props);
    this.state = {
      fname: "",
      email: "",
      company: "",
      message: "",
      topic: "",
      mailSent: false,
      error: null
    };
  }

  componentDidMount() {
    document.body.classList.add("page-contact");
    document.title = "Eternic – Global Payments Platform";
      ReactGA.pageview(window.location.pathname + window.location.search);
  }

  componentWillUnmount() {
    document.body.classList.remove("page-contact");
  }

  handleFormSubmit = e => {
    console.log(this.state);
    if (
      this.state.fname.length > 0 &&
      this.state.email.length > 0 &&
      this.state.message.length > 0
    ) {
      e.preventDefault();
      axios({
        method: "post",
        url: `${API_PATH}`,
        headers: { "content-type": "application/json" },
        data: this.state
      })
        .then(result => {
          this.setState({
            mailSent: result.data.sent
          });
          console.log(this.state);
        })
        .catch(error => this.setState({ error: error.message }));
    }
  };

  render() {
    return (
      <div className={"content"}>
        <MetaTags>
          <title>Eternic – Contact Us</title>
          <meta
            id="meta-description"
            name="description"
            content="Eternic is a financial technology company specializing in B2B international payments. Contact us to learn more about our global payments and settlement platform."
          />
          <meta id="og-title" property="og:title" content="Eternic" />
          <meta
            id="og-image"
            property="og:image"
            content="https://www.eternic.io/assets/hyperlink-photo.jpg"
          />
        </MetaTags>
        <section id="contact-page-wrapper">
          <div className="container">
            <div className="row">
              <div className="col-12 text-center">
                <h1>Let’s work together</h1>
              </div>
            </div>
            <div className="row">
              <div className="wrapper-map col-md-4 col-xl-6">
                <div className="background-element">
                  {/* <img src={require('../assets/images/contact.gif')} width="100%" alt="Torggata 15, 0181 Oslo Norway"/> */}
                </div>
                <div className="address-map">
                <a
                      href={
                        "https://www.google.no/maps/place/Torggata+15,+0181+Oslo/@59.9152887,10.7484128,17z/data=!3m1!4b1!4m5!3m4!1s0x46416e63c947c01b:0x102ad373936ea426!8m2!3d59.9152887!4d10.7506015"
                      }
                      rel="noopener noreferrer"
                      target="_blank"
                    >
                  <span className="dot"></span>
                  </a>
                  <div className="text-wrapper">
                    <a
                      href={
                        "https://www.google.no/maps/place/Torggata+15,+0181+Oslo/@59.9152887,10.7484128,17z/data=!3m1!4b1!4m5!3m4!1s0x46416e63c947c01b:0x102ad373936ea426!8m2!3d59.9152887!4d10.7506015"
                      }
                      rel="noopener noreferrer"
                      target="_blank"
                    >
                      Torggata 15, 0181
                      Oslo, Norway
                    </a>
                  </div>
                </div>
              </div>
              <div className="col-md-8 col-xl-6 text-left">
                <form action="/#">
                  <div className="row">
                    <div className="form-group col-sm-6">
                      <label>Full Name</label>
                      <input
                        type="text"
                        id="fname"
                        name="firstname"
                        placeholder=" "
                        required
                        value={this.state.fname}
                        onChange={e => this.setState({ fname: e.target.value })}
                      />
                    </div>
                    <div className="form-group col-sm-6">
                      <label>Company (Optional)</label>
                      <input
                        type="text"
                        id="company"
                        placeholder=" "
                        name="company"
                        value={this.state.company}
                        onChange={e =>
                          this.setState({ company: e.target.value })
                        }
                      />
                    </div>
                  </div>
                  <div className="row">
                    <div className="form-group col-sm-6">
                      <label>Email</label>
                      <input
                        type="email"
                        id="email"
                        name="email"
                        placeholder=" "
                        required
                        value={this.state.email}
                        onChange={e => this.setState({ email: e.target.value })}
                      />
                    </div>
                    <div className="col-md-6 text-left">
                      <div className="from-group">
                        <label>I would like info on:</label>
                        <div className="custom-radio">
                          <label>
                            <input
                              name="topic"
                              required
                              type="radio"
                              value="Partner Program"
                              onChange={e =>
                                this.setState({ topic: e.target.value })
                              }
                            />
                            <span className="text">Partner Program</span>
                            <span className="checkbg"></span>
                          </label>
                          <label>
                            <input
                              name="topic"
                              type="radio"
                              value="Jobs"
                              onChange={e =>
                                this.setState({ topic: e.target.value })
                              }
                            />
                            <span className="text">Jobs</span>
                            <span className="checkbg"></span>
                          </label>
                          <label>
                            <input
                              name="topic"
                              type="radio"
                              value="Other"
                              onChange={e =>
                                this.setState({ topic: e.target.value })
                              }
                            />
                            <span className="text">Other</span>
                            <span className="checkbg"></span>
                          </label>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="row">
                    <div className="form-group col-12">
                      <label>Message</label>
                      <textarea
                        id="subject"
                        name="subject"
                        placeholder=" "
                        required
                        onChange={e =>
                          this.setState({ message: e.target.value })
                        }
                        value={this.state.message}
                      />
                    </div>
                  </div>
                  <input
                    type="submit"
                    className="btn"
                    onClick={e => this.handleFormSubmit(e)}
                    value="Submit"
                  />
                  <div>
                    {this.state.mailSent && (
                      <div className="sucsess">
                        Thank you for contacting us.
                      </div>
                    )}
                    {this.state.error && (
                      <div className="error">Sorry we had some problems.</div>
                    )}
                  </div>
                </form>
              </div>
              <div className="col d-block d-md-none">
                <div className="visit-us">
                  <p>Visit us at:</p>
                  <a
                    href={
                      "https://www.google.no/maps/place/Torggata+15,+0181+Oslo/@59.9152887,10.7484128,17z/data=!3m1!4b1!4m5!3m4!1s0x46416e63c947c01b:0x102ad373936ea426!8m2!3d59.9152887!4d10.7506015"
                    }
                    rel="noopener noreferrer"
                    target="_blank"
                  >
                    Torggata 15, Oslo, 0181
                    <svg
                      width="11"
                      height="13"
                      viewBox="0 0 11 13"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        d="M0.0959296 7.21094L0.0959297 5.78906L8.37791 5.78906L4.57267 1.72656L5.53198 0.710938L11 6.5L5.53198 12.2891L4.57267 11.2734L8.37791 7.21094L0.0959296 7.21094Z"
                        fill="#FF5E53"
                      />
                    </svg>
                  </a>
                </div>
              </div>
              {/*<div className="col-sm-6">*/}
              {/*</div>*/}
            </div>
          </div>
        </section>
      </div>
    );
  }
}

export default Contact;

import React, { Component } from "react";
import { researchPapers } from "../assets/data/data";
import "react-multi-carousel/lib/styles.css";
import { Link } from "react-router-dom";
import Lottie from "./animations/HomeAnimation";
import MetaTags from "react-meta-tags";
import ReactGA from "react-ga";

import NewsSlider from "../components/core/NewsSlider";

class Home extends Component {
  constructor(props) {
    super(props);

    this.state = {
      isTop: true,
      data: "Data from parent",
    };
  }

  componentDidMount() {
    document.body.classList.add("page-home");

    ReactGA.pageview(window.location.pathname + window.location.search);
    console.log(window.location.pathname);
  }

  componentWillUnmount() {
    document.body.classList.remove("page-home");
  }

  render() {
    const TheNewPathForwardText = () => (
      <div>
        <h2>The new path forward</h2>
        <p>
          Using the latest in distributed ledger and tokenization technology,
          Eternic creates a new pathway forward where global transactions can
          flow between banks securely in real-time – saving you and your
          customers valuable time and money.
        </p>
        <Link className="btn" to="/cross-border-payments-solution">
          Learn more
        </Link>
      </div>
    );

    return (
      <div className={"content"}>
        <MetaTags>
          <title>Eternic – Global Payments Platform</title>
          <meta
            id="meta-description"
            name="description"
            content="Eternic is a global payments and settlement platform for financial institutions to make faster, safer and cheaper international transfers. Learn more here."
          />
          <meta id="og-title" property="og:title" content="Eternic" />
          <meta
            id="og-image"
            property="og:image"
            content="https://www.eternic.io/assets/hyperlink-photo.jpg"
          />
        </MetaTags>

        <video autoPlay playsInline muted loop className="hero-video desktop">
          <source
            src={require("../assets/videos/CalmerDistort.mp4")}
            type="video/mp4"
          />
          Your browser does not support HTML5 video.
        </video>

        <video autoPlay playsInline muted loop className="hero-video mobile">
          <source
            src={require("../assets/videos/mobile-homepage.mp4")}
            type="video/mp4"
          />
          Your browser does not support HTML5 video.
        </video>

        <section id="intro">
          <div className="wrapper">
            <div className="container">
              <div className="row">
                <div className="col-md-6 align-self-center order-2 order-md-1">
                  <Lottie />
                </div>
                <div className="col-md-6 col-xl-5 order-1 order-md-2">
                  <div>
                    <h2>
                      We’re moving fast. <br /> Shouldn’t our money?
                    </h2>
                    <p>
                      Our interconnected, global economy is growing rapidly and
                      will require a faster, more efficient banking
                      infrastructure, unburdened by costly, circuitous and
                      outdated systems. Is your global payments system ready to
                      meet the demand?
                    </p>
                  </div>
                  <div className="d-none d-md-block">
                    <TheNewPathForwardText />
                  </div>
                </div>
                <div className="col-md-6 col-xl-5 order-3 d-block d-md-none">
                  <TheNewPathForwardText />
                </div>
              </div>
            </div>
          </div>
        </section>

        <NewsSlider SliderTitle="News & Insight" />

        <section id="resources">
          <div className="container">
            <div className="row">
              <div className="col-xl-10 offset-xl-1">
                <h2 className="text-center">Our Latest Research</h2>
                {researchPapers
                  .map((item, index) => (
                    <a
                      key={index}
                      href={"/files/" + item.file + ".pdf"}
                      rel="noopener noreferrer"
                      target="_blank"
                    >
                      <div className="card" key={index}>
                        <div>
                          <h4>{item.title}</h4>
                          <p>{item.description}</p>
                        </div>
                        <div className="btn-wrapper align-self-center">
                          <div className="btn d-none d-md-block">Read Now</div>
                          <svg
                            width="13"
                            height="16"
                            viewBox="0 0 13 16"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg"
                            className="arrow d-block d-md-none"
                          >
                            <path
                              d="M0.113371 8.875L0.113372 7.125L9.90116 7.125L5.40407 2.125L6.53779 0.875L13 8L6.53779 15.125L5.40407 13.875L9.90116 8.875L0.113371 8.875Z"
                              fill="white"
                            />
                          </svg>
                        </div>
                      </div>
                    </a>
                  ))
                  .reverse()}
              </div>
            </div>
          </div>
        </section>
      </div>
    );
  }
}

export default Home;

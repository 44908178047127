import React, { Component } from "react";
import { Link, Route } from "react-router-dom";
// import MailchimpSubscribe from "react-mailchimp-subscribe";
// const url = "//eternic.us20.list-manage.com/subscribe/post?u=e3e96911071d366428b2cddc2&amp;id=cb5cbb3f1e";
// const SimpleForm = () => <MailchimpSubscribe url={url} />;

class Footer extends Component {
  render() {
    const JoinViaButtonPartnership = () => (
      <Link className="btn" to="/contact">
        Contact Us!
      </Link>
    );

    const JoinViaButtonOther = () => (
      <Link className="btn" to="/partnership">
        Tell me more
      </Link>
    );

    const JoinVia = () => (
      <div className="row" id={"footer-join-via"}>
        <div className="col-12 col-md-12 col-xl-6">
          <div className="join-via-card">
            <h3>Become a Partner - Join VIA</h3>
            <div className="text-wrapper">
              Our mission is to use technology to help you meet your customer’s
              needs faster. VIA means “the way”. Join our VIA partner program
              and we’ll show you the way to get faster, safer, less expensive
              cross-border payments.
            </div>
            <Route path="/" exact component={JoinViaButtonOther} />
            <Route path="/company" exact component={JoinViaButtonOther} />
            <Route path="/solution" exact component={JoinViaButtonOther} />
            <Route path="/contact" exact component={JoinViaButtonOther} />
            <Route
              path="/cross-border-payments-solution"
              exact
              component={JoinViaButtonOther}
            />
            <Route
              path="/partnership"
              exact
              component={JoinViaButtonPartnership}
            />
          </div>
        </div>
      </div>
    );

    const CareersSection = () => (
      <div className="row" id={"footer-join-via"}>
        <div className="col-12 col-md-12 col-xl-6">
          <div className="join-via-card">
            <h4>We’re Hiring!</h4>
            <div className="text-wrapper">
              It takes a talented and dedicated team to design and build
              innovative solutions for the financial market. If you have
              experience in payments, platform development or blockchain
              technology, we want to hear from you!.
            </div>
            <Link className="btn" to="/careers">
              Learn more
            </Link>
          </div>
        </div>
      </div>
    );

    const talentAndTechSection = () => (
      <div className="row" id={"footer-join-via"}>
        <div className="col-12 col-md-12 col-xl-6">
          <div className="join-via-card">
            <h4>Tech & Talent</h4>
            <div className="text-wrapper">
              We are always looking for talented developers with mad skills and
              technical aptitude. If you have experience in these areas, we want
              to hear from you.
            </div>
            <a
              className={"btn"}
              rel="noopener noreferrer"
              target="_blank"
              href="https://www.eternic.io/contact"
            >
              Join us
            </a>
          </div>
        </div>
      </div>
    );

    const FooterDynamicComponentChanger = () => (
      <div>
        <Route path="/" exact component={JoinVia} />
        <Route path="/company" exact component={CareersSection} />
        <Route path="/careers" exact component={talentAndTechSection} />
        <Route path="/solution" exact component={JoinVia} />
        <Route path="/contact" exact component={JoinVia} />
        <Route
          path="/cross-border-payments-solution"
          exact
          component={JoinVia}
        />
        <Route path="/partnership" exact component={JoinVia} />
      </div>
    );

    const FooterImage = () => (
      <img
        src={require("../../assets/images/globe.gif")}
        alt="Globe Animated"
      />
    );

    const FooterTop = () => (
      <div className="row">
        <div className="col-sm-auto col-6">
          <div className="w100 title">
            <span className="title">Solution</span>
          </div>
          <ul className="list-unstyled">
            <li>
              <Link to="/cross-border-payments-solution">Platform</Link>
            </li>
            <li>
              <Link to="/partnership">Partnership</Link>
            </li>
          </ul>
        </div>
        <div className="col-sm-auto col-6">
          <div className="w100 title">
            <span className="title">Resources</span>
          </div>
          <ul className="list-unstyled">
            <li>
              <a
                href={"https://www.eternic.io/#resources"}
                rel="noopener noreferrer"
                target="_blank"
              >
                Research
              </a>
            </li>
            <li>
              <a
                href={"/files/info-sheet_learn-more-about-eternic.pdf"}
                rel="noopener noreferrer"
                target="_blank"
              >
                Info Sheet
              </a>
            </li>
          </ul>
        </div>
        <div className="col-sm-auto col-6">
          <div className="w100 title">
            <span className="title">Company</span>
          </div>
          <ul className="list-unstyled">
            <li>
              <Link to="/company">About</Link>
            </li>
            <li>
              <Link to="/careers">Careers</Link>
            </li>
            <li>
              <Link to="/contact">Contact</Link>
            </li>
          </ul>
        </div>
        <div className="col-sm-auto col-6">
          <div className="w100 title">
            <span className="title">Our Ecosystem</span>
          </div>
          <ul className="list-unstyled">
            <li>
              <a
                target="_blank"
                rel="noopener noreferrer"
                href="https://www.fintechinnovation.no/"
              >
                Fintech Innovation
              </a>
            </li>
            <li>
              <a
                target="_blank"
                rel="noopener noreferrer"
                href="https://www.neonomics.io/"
              >
                Neonomics
              </a>
            </li>
          </ul>
        </div>
        <div className="col-sm-auto col-6">
          <div className="w-100 title d-none-wu635">
            <span className="title">Follow Us</span>
          </div>
          <ul className="list-unstyled some-links mr-4">
            <li>
              <a
                id={"some-medium"}
                rel="noopener noreferrer"
                target="_blank"
                href="https://medium.com/eternic"
              >
                <svg
                  width="21"
                  height="21"
                  viewBox="0 0 21 21"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    fillRule="evenodd"
                    clipRule="evenodd"
                    d="M2 0H19C20.1046 0 21 0.895431 21 2V19C21 20.1046 20.1046 21 19 21H2C0.89543 21 0 20.1046 0 19V2C0 0.89543 0.895431 0 2 0ZM4.83346 6.56238C4.96278 6.67894 5.02882 6.85011 5.01129 7.02329V13.2587C5.04966 13.4836 4.97875 13.7134 4.82029 13.8776L3.33839 15.6751V15.9122H7.5404V15.6751L6.0585 13.8776C5.89887 13.7137 5.82358 13.4854 5.85433 13.2587V7.86609L9.54261 15.9122H9.97072L13.1387 7.86609V14.2793C13.1387 14.4504 13.1387 14.4834 13.0267 14.5953L11.8873 15.7015V15.9385H17.4197V15.7015L16.3198 14.6216C16.2227 14.5476 16.1746 14.426 16.1947 14.3056V6.37144C16.1746 6.25104 16.2227 6.1294 16.3198 6.05539L17.4461 4.97555V4.73851H13.547L10.7677 11.6718L7.60626 4.73851H3.51622V4.97555L4.83346 6.56238Z"
                    fill="#444444"
                  />
                </svg>
                <span className="d-none-wu635">Medium</span>
              </a>
            </li>
            <li>
              <a
                id={"some-linkedin"}
                rel="noopener noreferrer"
                target="_blank"
                href="https://www.linkedin.com/company/eternic/"
              >
                <svg
                  width="21"
                  height="21"
                  viewBox="0 0 21 21"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M19.4493 0H1.55164C0.694624 0 0 0.673435 0 1.50462V19.495C0 20.3262 0.694869 21.0002 1.55164 21.0002H19.4493C20.3062 21.0002 21 20.3258 21 19.495V1.50462C21 0.673679 20.3062 0 19.4493 0ZM6.3666 17.5786H3.19365V8.09728H6.3666V17.5786ZM4.78044 6.80197H4.75911C3.69508 6.80197 3.00534 6.07429 3.00534 5.16328C3.00534 4.2339 3.71534 3.52595 4.80046 3.52595C5.88568 3.52595 6.55321 4.23371 6.5743 5.16328C6.5743 6.07448 5.88593 6.80197 4.78044 6.80197ZM17.8042 17.5786H14.6322V12.5058C14.6322 11.2311 14.1726 10.3614 13.0256 10.3614C12.148 10.3614 11.6271 10.9481 11.3984 11.5146C11.3136 11.717 11.2926 12 11.2926 12.2826V17.5784H8.1213C8.1213 17.5784 8.1628 8.98637 8.1213 8.09713H11.2931V9.44132C11.7142 8.79542 12.4666 7.87405 14.151 7.87405C16.2387 7.87405 17.8042 9.22839 17.8042 12.142V17.5786ZM11.2726 9.47144C11.278 9.46255 11.2856 9.45186 11.2931 9.44146V9.47144H11.2726Z"
                    fill="#444444"
                  />
                </svg>
                <span className="d-none-wu635">LinkedIn</span>
              </a>
            </li>
            <li>
              <a
                id={"some-twitter"}
                rel="noopener noreferrer"
                target="_blank"
                href="https://twitter.com/eternic_io"
              >
                <svg
                  width="21"
                  height="21"
                  viewBox="0 0 21 21"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    fillRule="evenodd"
                    clipRule="evenodd"
                    d="M2.625 21H18.375C19.824 21 21 19.824 21 18.375V2.625C21 1.176 19.824 0 18.375 0H2.625C1.176 0 0 1.176 0 2.625V18.375C0 19.824 1.176 21 2.625 21ZM15.7239 8.17409C15.7239 11.7283 13.0149 15.8338 8.06419 15.8338C6.54694 15.8338 5.12943 15.3876 3.94293 14.6316C4.15293 14.6578 4.36818 14.6683 4.58343 14.6683C5.84343 14.6683 7.00368 14.2378 7.92768 13.5133C6.75168 13.4923 5.75418 12.7153 5.41293 11.6443C5.57568 11.6758 5.74368 11.6916 5.91693 11.6916C6.16368 11.6916 6.39993 11.6601 6.62568 11.5971C5.39718 11.3503 4.46793 10.2583 4.46793 8.95634V8.91959C4.83018 9.11909 5.24493 9.23984 5.68593 9.25559C4.96668 8.77259 4.48893 7.94834 4.48893 7.01384C4.48893 6.52034 4.62018 6.05834 4.85118 5.65934C6.17943 7.28684 8.16393 8.36309 10.4004 8.47334C10.3584 8.27384 10.3322 8.06909 10.3322 7.85909C10.3322 6.37334 11.5397 5.16584 13.0254 5.16584C13.7972 5.16584 14.4954 5.49134 14.9889 6.01634C15.6032 5.89559 16.1807 5.67509 16.7004 5.36534C16.4957 5.99534 16.0704 6.52559 15.5139 6.85634C16.0599 6.78809 16.5797 6.64634 17.0627 6.43109C16.7004 6.97184 16.2437 7.44959 15.7187 7.82759C15.7239 7.94309 15.7239 8.05859 15.7239 8.17409Z"
                    fill="#444444"
                  />
                </svg>
                <span className="d-none-wu635">Twitter</span>
              </a>
            </li>
          </ul>
          {/*<div id={"some-newsletter-form"}>*/}
          {/*<div className="form-group">*/}
          {/*<SimpleForm />*/}
          {/*</div>*/}
          {/*</div>*/}
          {/* <form className="form-inline" id={"some-newsletter-form"}>
            <div className="form-group">
              <input
                placeholder="Subscribe to our newsletter"
                className="form-control"
                type="email"
                name="email"
              />
              <input className="btn" type="submit" value="Subscribe" />
            </div>
            <div className="form-check">
              <label className="custom-checkbox" htmlFor="receiveUpdates">
                <input type="checkbox" id="receiveUpdates" />
                <span> I would like to receive updates</span>
              </label>
            </div>
          </form> */}
        </div>
        <div id={"footer-image"}>
          <Route path="/" exact component={FooterImage} />
          <Route
            path="/cross-border-payments-solution"
            exact
            component={FooterImage}
          />
          <Route path="/company" exact component={FooterImage} />
          <Route path="/careers" exact component={FooterImage} />
          <Route path="/contact" exact component={FooterImage} />
        </div>
      </div>
    );

    return (
      <footer>
        <section>
          <div className="container">
            <section id={"footer-dynamic-component"}>
              <FooterDynamicComponentChanger></FooterDynamicComponentChanger>
            </section>
            <section id={"footer-top"}>
              <FooterTop></FooterTop>
            </section>
          </div>
        </section>
      </footer>
    );
  }
}

export default Footer;

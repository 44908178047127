import React from 'react'
import Lottie from 'react-lottie';
import animationData from '../../../src/assets/animations/partnerPageMobile'

export default class HomeAnimation extends React.Component {

    constructor(props) {
        super(props);
        this.state = {isStopped: false, isPaused: false};
    }

    render() {
        const defaultOptions = {
            loop: true,
            autoplay: true,
            animationData: animationData,
            rendererSettings: {
                preserveAspectRatio: 'xMidYMid slice'
            }
        };

        return <div className="lottie-animation">
            <Lottie options={defaultOptions}
                    onClick={() => this.setState({isStopped: false})}
            />
        </div>
    }
}

export const researchPapers = [
    {
        title: 'Distributed Ledger Technologies for International Banking',
        description: 'A concise analysis of DLT applicability for global payments and settlement.',
        file: 'distributed-ledger-technologies_analysis',
    },
    {
        title: 'Smart Contact Risk in Financial Services',
        description: 'An assessment of DLT smart contract risk and mitigation strategies.',
        file: 'smart-contract-risk-in-financial-services',
    }
];

export const team = [
    {
        name: 'Christoffer Andvig',
        role: 'Founder',
        link: 'https://www.linkedin.com/in/christofferandvig/'
    },
    {
        name: 'David Goodwin',
        role: 'Strategy and Marketing Advisor',
        link: 'https://www.linkedin.com/in/david-goodwin-a6a28a53/'
    },
    {
        name: 'Frankie Elmquist',
        role: 'Product and Marketing Lead',
        link: 'https://www.linkedin.com/in/francine-frankie-elmquist-5044507a/'
    },
    {
        name: 'Sergey Popov',
        role: 'Senior SOA Architect',
        link: 'https://www.linkedin.com/in/sergey-popov-3bb98426'
    },
    {
        name: 'Kenneth Wagenius',
        role: 'Technical Advisor',
        link: 'https://www.linkedin.com/in/kenneth-wagenius/'
    }
];
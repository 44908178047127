import React, {Component} from "react";
import MetaTags from "react-meta-tags";
import ReactGA from "react-ga";

class Solution extends Component {
    componentDidMount() {
        document.body.classList.add("page-solution");
        ReactGA.pageview(window.location.pathname + window.location.search);
    }

    componentWillUnmount() {
        document.body.classList.remove("page-solution");
    }

    render() {
        return (
            <div className={"content"}>
                <MetaTags>
                    <title>Eternic – Cross Border Payments Solution</title>
                    <meta
                        id="meta-description"
                        name="description"
                        content="Eternic is a cross border payments platform for financial institutions to make faster, safer and cheaper international transfers. Learn more about our solution."
                    />
                    <meta id="og-title" property="og:title" content="Eternic"/>
                    <meta
                        id="og-image"
                        property="og:image"
                        content="https://www.eternic.io/assets/hyperlink-photo.jpg"
                    />
                </MetaTags>

                <video autoPlay playsInline muted loop className="hero-video d-none d-md-block">
                    <source src={require("../assets/videos/solution.mp4")}
                            type="video/mp4"/>
                    Your browser does not support HTML5 video.
                </video>

                <video autoPlay playsInline muted loop className="hero-video d-block d-md-none">
                    <source
                        src={require("../assets/videos/mobile-platform.mp4")}
                        type="video/mp4"
                    />
                    Your browser does not support HTML5 video.
                </video>

                <section id="intro">
                    <div className="container">
                        <div className="row">
                            <div className="col-md-6 offset-md-3 text-md-center">
                                <h2>Our Platform</h2>
                                <p className="mb-0">
                                    The Eternic cross-border payments platform enables financial
                                    institutions to make faster, safer and cheaper international
                                    transfers using the latest advances in digital traceability
                                    and value transfer.
                                </p>
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-md-6 align-self-center embed-responsive">
                                <video
                                    autoPlay
                                    playsInline
                                    muted
                                    loop
                                    className="content-video embed-responsive-item"
                                >
                                    <source
                                        src={require("../assets/videos/token.mp4")}
                                        type="video/mp4"
                                    />
                                    Your browser does not support HTML5 video.
                                </video>
                            </div>
                            <div className="col-md-5 align-self-center">
                                <div>
                                    <h3>Distributed Ledger Technology (DLT)</h3>
                                    <p>
                                        Safely enter into transactions through a permission-based
                                        distributed ledger. Transact in a secure environment that is
                                        less vulnerable to fraud and cyberattack. Enable
                                        participants access to the data for status, oversight and
                                        reconciliation.
                                    </p>
                                </div>
                                <div>
                                    <h3>Stable Transfer Mechanism</h3>
                                    <p>
                                        Introduce uniformity and speed in the transfer of currencies
                                        through tokenization. Apply local regulations as well as
                                        platform-specific rules to every transaction to assure
                                        appropriate governance is satisfied before the transfer is
                                        initiated.
                                    </p>
                                </div>
                                <div>
                                    <h3>Dynamic Settlement Solution</h3>
                                    <p>
                                        Mitigate liquidity risk by defining your settlement strategy
                                        – either prioritizing time of delivery or lowest possible
                                        cost.
                                    </p>
                                </div>
                                <div>
                                    <h3>Interoperablity</h3>
                                    <p>
                                        Our modular and structured platform uses a well-defined API
                                        to make deployment and connection with other open banking
                                        platforms quick and easy.
                                    </p>
                                </div>
                            </div>
                            {/* <div className="col-12 text-center d-none d-md-block">
                <a
                  href={"/files/info-sheet_learn-more-about-eternic.pdf"}
                  rel="noopener noreferrer"
                  target="_blank"
                  className="btn"
                >
                  Download Infosheet
                </a>
              </div> */}
                        </div>
                    </div>
                </section>

                <section id="smarter-system">
                    <div className="wrapper">
                        <div className="container">
                            <div className="row">
                                <div className="col-md-8 offset-md-2 text-md-center">
                                    <div>
                                        <h4 className="text-center">A Smarter Settlement System</h4>
                                    </div>
                                    <div className="text-center">
                                        <p>
                                            Our interbank transfer mechanism is tied to fiat currency
                                            and designed to work in tandem with existing financial
                                            systems so you can deploy quickly and reduce transfers
                                            costs by improving your transfer systems efficiency,
                                            transparency, and risk management.
                                        </p>
                                    </div>
                                    <div>
                                        <div className="list">
                                            <div>Improve security</div>
                                            <div>Reduce risk-related overhead</div>
                                            <div>Streamline governance</div>
                                            <div>Enable faster transactions</div>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-12 text-center pt-4">
                                    <a
                                        href={"/files/info-sheet_learn-more-about-eternic.pdf"}
                                        rel="noopener noreferrer"
                                        target="_blank"
                                        className="btn"
                                    >
                                        Download Infosheet
                                    </a>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
            </div>
        );
    }
}

export default Solution;

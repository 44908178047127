import React, {Component} from "react";


class JobListing extends Component {

    constructor(props, context) {
        super(props, context);

        this.state = {
            positions: []
        }
    }

    componentDidMount() {
        fetch('https://recruiter-api.hr-manager.net/jobportal.svc/fintech/positionlist/json/?depid=18958&incads=yes&plainads=yes')
            .then(results => {
                return results.json();
            }).then(data => {
            console.log(data.Items);
            this.setState({positions: data.Items})
        })
    }

    render() {
        let positions = this.state.positions.map((position, index) => {
                return <a key={index} target="_blank" rel="noopener noreferrer" href={position.AdvertisementUrlSecure}
                          className="col-12 col-listing">
                    <div className="card">
                        <div>
                            <h4>{position.Name}</h4>
                            <p>{position.Advertisements[0].Content.split(/\n/)[0]}</p>
                        </div>
                        <div className="btn-wrapper align-self-center">
                            <div className="btn d-none d-md-block">Join us</div>
                            <svg width="13"
                                 height="16"
                                 viewBox="0 0 13 16"
                                 fill="none"
                                 xmlns="http://www.w3.org/2000/svg"
                                 className="arrow d-block d-md-none">
                                <path
                                    d="M0.113371 8.875L0.113372 7.125L9.90116 7.125L5.40407 2.125L6.53779 0.875L13 8L6.53779 15.125L5.40407 13.875L9.90116 8.875L0.113371 8.875Z"
                                    fill="white"/>
                            </svg>
                        </div>
                    </div>
                </a>;
            }
        );

        return (
            <section id={"jobs"}>
                <div className="container">
                    <div className="row">
                        <div className="col-xl-10 offset-xl-1">
                            <h2 className="text-center">Opportunity Awaits</h2>
                            <div className="row justify-content-center" id="listing-wrapper">
                                { positions.length !== 0 ? positions : <p>No positions available? Be the first to know when there are. <a className="link" href={'https://www.linkedin.com/company/eternic'} target="_blank" rel="noopener noreferrer">Follow us on Linkedin.</a></p> }
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        )
    }
}

export default JobListing;

import React, { Component } from "react";
import MetaTags from "react-meta-tags";
import ReactGA from "react-ga";
import ScrollContainer from "react-indiana-drag-scroll";
import JobListing from "./core/JobListing";
import "react-multi-carousel/lib/styles.css";
import NewsSlider from "../components/core/NewsSlider";


let careersImages = [];

function LifeAtEternic() {
  return (
    <section id="intro">
      <div className="container">
        <div className="row">
          <div className="col-lg-5 col-md-7 text-md-left">
            <h2>Life at Eternic</h2>
            <p>
              The Eternic cross-border payments solution is being developed by
              Fintech Innovation, a leading fintech development company and hub
              in Oslo, Norway. We're located in the heart of the city with easy
              access to transportation, shops, and city life. When we're not
              hard at work, we're taking advantage of all our fintech culture
              has to offer - tech talks, yoga, Friday beers, fun outings on the
              fjord and in the mountains, and more!
            </p>
          </div>
        </div>
      </div>
      <div className="container-fluid">
        <ScrollContainer nativeMobileScroll className="scroll-container row">
          <div className="team-wrapper">
            {careersImages.map((image, index) => (
              <div key={index} className="team-members">
                <img
                  draggable={false}
                  className="logo"
                  width="100%"
                  src={image}
                  alt={image.name}
                />
              </div>
            ))}
          </div>
        </ScrollContainer>
      </div>
    </section>
  );
}

function WhatSetsUsApart() {
  return (
    <section id="work-life">
      <div className="wrapper">
        <div className="container">
          <div className="row">
            <div className="col-md-6 offset-md-3 text-md-center">
              <h2>What Sets Us Apart</h2>
            </div>
          </div>
          <div className="row flex-column-reverse flex-md-row">
            <div className="col-md-6 align-self-center illustration-wrapper">
              <div className={"illustration"}>
                <img src={require("../assets/images/words.svg")} alt="" />
              </div>
            </div>
            <div className="col-md-5 align-self-center">
              <CuttingEdgeTech />
              <SupportiveStructure />
              <DiverseCommunity />
              <WorkLifeBalance />
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}

function CuttingEdgeTech() {
  return (
    <div>
      <h3>Cutting Edge Tech</h3>
      <p>
        Fintech is one of the fastest growing industries in Norway and around
        the world. Work with cutting edge new technology that's reshaping the
        financial industry.
      </p>
    </div>
  );
}

function SupportiveStructure() {
  return (
    <div>
      <h3>Supportive Structure</h3>
      <p>
        We believe that every team member has something to offer. Have voice in
        what we're building, and work in an environment where you can learn,
        grow and thrive.
      </p>
    </div>
  );
}

function DiverseCommunity() {
  return (
    <div>
      <h3>Our Diverse Community</h3>
      <p>
        Diversity, equality and inclusion are core values for us. Our
        multi-cultural team includes varied work experiences, nationalities,
        ages, and genders that combine to form a great team.
      </p>
    </div>
  );
}

function WorkLifeBalance() {
  return (
    <div>
      <h3>Work-Life Balance</h3>
      <p>
        True to our Scandinavian roots, we support work-life balance. To promote
        this, we offer flexible hours, a fantastic canteen, social activities,
        and excellent benefits.
      </p>
    </div>
  );
}

class Careers extends Component {
  componentWillMount() {
    let r = require.context(
      "../assets/images/careers/",
      false,
      /\.(png|jpe?g|svg)$/
    );
    careersImages = r.keys().map(r);
  }

  componentDidMount() {
    document.body.classList.add("page-career");
    ReactGA.pageview(window.location.pathname + window.location.search);
  }

  componentWillUnmount() {
    document.body.classList.remove("page-career");
  }

  render() {
    return (
      <div className={"content"}>
        <MetaTags>
          <title>Eternic – Careers</title>
          <meta
            id="meta-description"
            name="description"
            content="Eternic is a FinTech specializing in B2B international payments. Our global payments and settlement platform provides faster, cheaper and safer transfers."
          />
          <meta id="og-title" property="og:title" content="Eternic" />
          <meta
            id="og-image"
            property="og:image"
            content="https://www.eternic.io/assets/hyperlink-photo.jpg"
          />
        </MetaTags>

        <video autoPlay playsInline muted loop className="hero-video desktop">
          <source
            src={require("../assets/videos/Innovate.mp4")}
            type="video/mp4"
          />
          Your browser does not support HTML5 video.
        </video>

        <video autoPlay playsInline muted loop className="hero-video mobile">
          <source
            src={require("../assets/videos/Innovate.mp4")}
            type="video/mp4"
          />
          Your browser does not support HTML5 video.
        </video>

        <LifeAtEternic />

        <WhatSetsUsApart />

        <JobListing />

        <NewsSlider SliderTitle='Learn More About Us' />
      
      </div>
    );
  }
}

export default Careers;

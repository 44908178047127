import React, { Component } from "react";
import { Link, Route } from "react-router-dom";
import Carousel from "react-multi-carousel";
import "react-multi-carousel/lib/styles.css";

class NewsSlider extends Component {
  constructor(props) {
    super(props);

    this.state = {
      articles: [],
      sliderTitle: this.props.SliderTitle
    };
  }

  componentDidMount() {
   this.FetchArticles();
  }

  FetchArticles() {
    fetch(
        "https://api.rss2json.com/v1/api.json?rss_url=https://medium.com/feed/eternic"
      )
        .then((res) => res.json())
        .then((json) => this.setState({ articles: json.items }));
  }

  RenderArticleCarousel() {
    const carouselSettings = {
      desktop: {
        breakpoint: {
          max: 3000,
          min: 1024,
        },
        items: 3,
        partialVisibilityGutter: 400,
      },
      mobile: {
        breakpoint: {
          max: 464,
          min: 0,
        },
        items: 1,
        partialVisibilityGutter: 30,
      },
      tablet: {
        breakpoint: {
          max: 1024,
          min: 464,
        },
        items: 2,
        partialVisibilityGutter: 30,
      },
    };
    return (
      <Carousel
        additionalTransfrom={0}
        centerMode={false}
        infinite={true}
        slidesToSlide={1}
        swipeable
        showDots={true}
        keyBoardControl={true}
        responsive={carouselSettings}
      >
        {this.state.articles.map((article, index) => (
          <a
            rel="noopener noreferrer"
            target="blank"
            key={index}
            href={article.link}
          >
            <img className={"cover-image"} src={article.thumbnail} alt="" />
            <div className="description">
              <div className="title button">{article.title}</div>
            </div>
          </a>
        ))}
      </Carousel>
    );
  }

  render() {
    return (
      <section id={"news-slider"}>
        <div className="container">
          <div className="row">
            <div className="col-12 text-center">
                <h2>{this.state.sliderTitle}</h2>
              {this.RenderArticleCarousel()}
            </div>
          </div>
        </div>
      </section>
    );
  }
}

export default NewsSlider;

import React, {Component} from 'react';
import {Redirect, Route, Switch} from 'react-router-dom';
import Home from "./components/Home";
import About from "./components/About";
import Contact from "./components/Contact";
import Solution from "./components/Solution";
import PartnerProgram from "./components/PartnerProgram";
import Careers from "./components/Careers";


class Router extends Component {
    // constructor(props) {
    //     super(props);
    // }

    render() {
        return (

            <Switch>
                <Route exact path='/' component={Home}/>
                <Route path='/cross-border-payments-solution' component={Solution}/>
                <Route path='/partnership' component={PartnerProgram}/>
                <Route path='/company' component={About}/>
                <Route path='/careers' component={Careers}/>
                <Route path='/contact' component={Contact}/>
                <Redirect to="/"/>
            </Switch>

        );
    }
};

export default Router;

import React from "react";
import { BrowserRouter } from "react-router-dom";
import "bootstrap/dist/css/bootstrap.min.css";
import "./assets/stylesheets/css/index.css";
import Router from "./routes";
import Header from "./components/core/Header";
import Footer from "./components/core/Footer";
import ResetScroll from "./components/core/ResetScroll";
import ReactGA from "react-ga";

import { hotjar } from "react-hotjar";
import TagManager from "react-gtm-module";

const tagManagerArgs = {
  gtmId: "GTM-PG8HB2L",
};

TagManager.initialize(tagManagerArgs);

ReactGA.initialize("UA-135015098-1");

hotjar.initialize(1346741, 6);

function App() {

  return (
    <BrowserRouter>
      <ResetScroll>
        <div className="App">
          <Header />
          <Router />
          <Footer />
          <div id={"legal"}>
            <section>
              <div className="container">
                <div className="row">
                  <div className="col">
                    <div>© Eternic</div>
                  </div>
                </div>
              </div>
            </section>
          </div>
        </div>
      </ResetScroll>
    </BrowserRouter>
  );
}

export default App;

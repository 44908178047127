import React from "react";
import Lottie from "react-lottie";
import animationData from "../../../src/assets/animations/arrow";
import mobileAnimationData from "../../../src/assets/animations/mobile-arrow.json";

export default class HomeAnimation extends React.Component {
  constructor(props) {
    super(props);
    this.state = { isStopped: false, isPaused: false };
  }

  render() {
    const defaultOptions = {
      loop: true,
      autoplay: true,
      animationData: animationData,
      rendererSettings: {
        preserveAspectRatio: "xMidYMid slice"
      }
    };

    const mobileOptions = {
      loop: true,
      autoplay: true,
      animationData: mobileAnimationData,
      rendererSettings: {
        preserveAspectRatio: "xMidYMid slice"
      }
    };

    return (
      <div className="lottie-animation">
        <div className="d-none d-sm-block">
          <Lottie
            options={defaultOptions}
            onClick={() => this.setState({ isStopped: false })}
          />
        </div>
        <div className="d-block d-sm-none">
          <Lottie
            options={mobileOptions}
            onClick={() => this.setState({ isStopped: false })}
          />
        </div>
      </div>
    );
  }
}

import React, {Component} from "react";
import {Link, NavLink, Route} from "react-router-dom";
import { Link as SLink } from "react-scroll";
import {Nav, Navbar} from "react-bootstrap";
import {CSSTransition} from "react-transition-group";


class Header extends Component {

    constructor(props) {
        super(props);
        this.state = {
            activeKey: 0,
            isHovered: false,
            viaLinkTrigger: true,
            menu: false
        };
        this.handleMenuHover = this.handleMenuHover.bind(this);
        this.toggleMenu = this.toggleMenu.bind(this);
    }

    toggleMenu() {
        this.setState({menu: !this.state.menu});
    }

    handleMenuHover() {
        this.setState({
            isHovered: !this.state.isHovered
        });
    }

    componentDidUpdate(prevProps, prevState) {
    }

    render() {
        const homeHero = () => (
            <section id={"hero-home"} className="hero-section">
                <div className="container">
                    <div className="row">
                        <div className="col-lg-6 col-md-8">
                            <h1>The Fluid Solution For Global Payment And Settlement</h1>
                            <div className="text-wrapper">
                                <p>
                                    The Eternic platform provides a new pathway for global
                                    payments and settlement to flow directly between banks ~ the
                                    future is fluid
                                </p>
                                <div className="text-center text-md-left">
                                    <Link className="btn" to="/cross-border-payments-solution">
                                        Learn more
                                    </Link>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        );

        const solutionHero = () => (
            <section id={"heroSolution"} className="hero-section">
                <div className="container">
                    <div className="row">
                        <div className="col-lg-5 col-md-8">
                            <h1>The Eternic Solution</h1>
                            <div className="text-wrapper">
                                <p>
                                    Global commerce and trade are advancing rapidly. Is your
                                    international payments system ready? Meet the demand for
                                    real-time cross-border payments and settlement with Eternic.
                                </p>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        );

        const careerHero = () => (
            <section id={"heroSolution"} className="hero-section">
                <div className="container">
                    <div className="row">
                        <div className="col-lg-5 col-md-8">
                            <h1>Come Innovate With Us</h1>
                            <div className="text-wrapper">
                                <p>
                                    It takes a talented and dedicated team to design and build innovative solutions for
                                    the financial market. If you have experience in payments, platform development, and
                                    blockchain technology - we want to hear from you!
                                </p>
                                <div className="text-center text-md-left">
                                    <SLink className="btn" to="jobs">
                                        View Opportunities
                                    </SLink>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        );

        const viaLink = () => (
            <section id="viaLink">
                <div className="via">
                    <Link to="/partnership">
                        Join VIA
                        <svg xmlns="http://www.w3.org/2000/svg" height="24" viewBox="0 0 24 24" width="24">
                            <path fill="#ffffff" d="M12 4l-1.41 1.41L16.17 11H4v2h12.17l-5.58 5.59L12 20l8-8z"/>
                        </svg>
                    </Link>
                </div>
            </section>
        );

        const ViaLinkLogic = () => (
            <React.Fragment>
                <Route path="/" exact component={viaLink}/>
                <Route path="/company" exact component={viaLink}/>
                <Route path="/cross-border-payments-solution" exact component={viaLink}/>
            </React.Fragment>
        );

        const show = this.state.menu ? "show" : "";
        return (
            <header className="App-header">
                <Navbar collapseOnSelect expand="md">
                    <div className="container">
                        <Link
                            onClick={() =>
                                this.setState(
                                    {locationPath: !this.state.locationPath},
                                    this.setState({menu: false})
                                )
                            }
                            to="/"
                            exact="true"
                            className="navbar-brand"
                        >
                            <img src={require("../../assets/images/logo.svg")} alt=""/>
                        </Link>
                        <Navbar.Toggle
                            onClick={this.toggleMenu}
                            aria-controls="basic-navbar-nav"
                            className={show}
                        >
                            <span></span>
                            <span></span>
                            <span></span>
                        </Navbar.Toggle>
                        <div
                            id="basic-navbar-nav"
                            className={"collapse navbar-collapse " + show}
                        >
                            <Nav className="ml-auto">
                                <div className={"nav-item dropdown-link d-none d-md-block"}>
                                    <NavLink
                                        className={"nav-link dropdown-nav-link"}
                                        to="/cross-border-payments-solution"
                                        isActive={(match, location) => {
                                            if (
                                                location.pathname ===
                                                "/cross-border-payments-solution" ||
                                                location.pathname === "/partnership"
                                            ) {
                                                return true;
                                            }
                                        }}
                                    >
                                        Solution
                                    </NavLink>
                                    <div className="dropdown-menu">
                                        <NavLink
                                            exact={true}
                                            className="nav-link"
                                            as={Link}
                                            to="/cross-border-payments-solution"
                                        >
                                            Platform
                                        </NavLink>
                                        <NavLink as={Link} className="nav-link" to="/partnership">
                                            Partnership
                                        </NavLink>
                                    </div>
                                </div>

                                <div className="nav-item d-sm-block d-md-none">
                                    <h2 className="mobile-title">Menu</h2>
                                </div>

                                <div className="nav-item d-sm-block d-md-none">
                                    <NavLink
                                        onClick={() =>
                                            this.setState(
                                                {locationPath: !this.state.locationPath},
                                                this.toggleMenu
                                            )
                                        }
                                        exact={true}
                                        className="nav-link"
                                        to=""
                                    >
                                        Home
                                    </NavLink>
                                </div>

                                <div className="nav-item d-sm-block d-md-none">
                                    <NavLink
                                        onClick={() =>
                                            this.setState(
                                                {locationPath: !this.state.locationPath},
                                                this.toggleMenu
                                            )
                                        }
                                        className="nav-link"
                                        to="/cross-border-payments-solution"
                                    >
                                        Platform
                                    </NavLink>
                                </div>

                                <div className="nav-item d-sm-block d-md-none">
                                    <NavLink
                                        onClick={() =>
                                            this.setState(
                                                {locationPath: !this.state.locationPath},
                                                this.toggleMenu
                                            )
                                        }
                                        className="nav-link"
                                        to="/partnership"
                                    >
                                        Join VIA
                                    </NavLink>
                                </div>

                                <div className="nav-item">
                                    <NavLink
                                        onClick={() =>
                                            this.setState(
                                                {locationPath: !this.state.locationPath},
                                                this.toggleMenu
                                            )
                                        }
                                        className="nav-link"
                                        to="/company"
                                    >
                                        Company
                                    </NavLink>
                                </div>

                                <div className="nav-item">
                                    <NavLink
                                        onClick={() =>
                                            this.setState(
                                                {locationPath: !this.state.locationPath},
                                                this.toggleMenu
                                            )
                                        }
                                        className="nav-link"
                                        to="/careers"
                                    >
                                        Careers
                                    </NavLink>
                                </div>

                                <div className="nav-item">
                                    <NavLink
                                        onClick={() =>
                                            this.setState(
                                                {locationPath: !this.state.locationPath},
                                                this.toggleMenu
                                            )
                                        }
                                        className="nav-link"
                                        to="/contact"
                                    >
                                        Contact
                                    </NavLink>
                                </div>
                            </Nav>
                        </div>
                    </div>
                </Navbar>
                <Route path="/" exact component={homeHero}/>
                <Route path="/cross-border-payments-solution" exact component={solutionHero}/>
                <Route path="/careers" exact component={careerHero}/>
                <CSSTransition
                    in={this.state.viaLinkTrigger}
                    timeout={300}
                    classNames="my-node"
                    unmountOnExit
                >
                    <ViaLinkLogic></ViaLinkLogic>
                </CSSTransition>
            </header>
        );
    }
}

export default Header;
